import React from 'react';
import '../pages/UserPage.css';

const UserInfo = ({ name, email, logout }) => {
    return (
        <div className="user_container">
            <div>
                Logged in as
                <h1>{name}</h1>
                <h2>{email}</h2>
            </div>
            <button className="user_btn" onClick={logout}>
                Logout
            </button>
        </div>
    );
};

export default UserInfo;
