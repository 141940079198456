import { logout, auth } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './UserPage.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect, useState } from 'react';
import Labels from '../components/Labels';
import UserInfo from '../components/UserInfo';

const UserPage = () => {
    const [user, loading] = useAuthState(auth);
    const [labels, setLabels] = useState([]);

    const navigate = useNavigate();

    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');
    const userId = localStorage.getItem('uid');
    const token = localStorage.getItem('token');

    const handleLogoutClick = async () => {
        await logout();
        navigate('/');
    };

    const getLabels = async () => {
        const response = await fetch(
            `https://gmail.googleapis.com/gmail/v1/users/${userId}/labels?access_token=${token}`,
            {
                method: 'GET'
            }
        );
        const data = await response.json();
        const labelsData = data.labels;

        const filteredData = labelsData.filter(
            (label) => label.type === 'user'
        );
        const editedData = filteredData.map((obj) => {
            return {
                ...obj,
                id: obj.id.split('_')[1],
                droppable: true,
                parent: 0,
                text: obj.name
            };
        });
        setLabels(editedData);
    };

    useEffect(() => {
        if (loading) return;
        if (!user) navigate('/');
        getLabels();
    }, [user, loading]);

    return (
        <div className="user">
            <UserInfo
                name={name}
                logout={handleLogoutClick}
                email={email}
            ></UserInfo>
            <Labels labels={labels} user={userId} token={token}></Labels>
        </div>
    );
};

export default UserPage;
