import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyAue96wedElS7xF-SIEh1CTSAWGe2m8pi4",
    authDomain: "reactauth-dccf9.firebaseapp.com",
    projectId: "reactauth-dccf9",
    storageBucket: "reactauth-dccf9.appspot.com",
    messagingSenderId: "968540775819",
    appId: "1:968540775819:web:fbf64e08389b39f89cbb8e"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/gmail.labels');

const signInWithGoogle = async () => {

    await signInWithPopup(auth, provider).then((result) => {

        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const name = result.user.displayName;
        const email = result.user.email;
        const uid = result.user.providerData[0].uid;

        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("uid", uid);
        localStorage.setItem("token", token);

    }).catch((error) => {
        console.log(error);
    });
}

const logout = async () => {
    await signOut(auth);
}

export {
    auth,
    signInWithGoogle,
    logout
}