import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, auth } from '../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import './Login.css';

const Login = () => {
    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const handleClick = async () => {
        await signInWithGoogle();
        navigate('/user');
    };

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user) navigate('/user');
    }, [user, loading]);

    return (
        <div className="login">
            <div className="login_container">
                <div className="title_container">
                    <h1>
                        <span className="googleRed">Gmail</span>{' '}
                        <span className="googleBlue">Labels</span>{' '}
                        <span className="googleGreen">Manager</span>
                    </h1>
                </div>
                <button onClick={handleClick} className="login-with-google-btn">
                    Sign In With Google
                </button>
            </div>
        </div>
    );
};

export default Login;
