import './App.css';
import Login from './pages/Login'
import UserPage from './pages/UserPage';
import { Routes, Route } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="user" element={<UserPage />}></Route>
      </Routes>
    </div>
  );
}
export default App;
