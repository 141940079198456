import { useEffect, useState } from 'react';
import './Labels.css';
import { Tree } from '@minoru/react-dnd-treeview';

const Labels = ({ labels, user, token }) => {
    const [treeData, setTreeData] = useState([]);
    const [dataAfterDrop, setDataAfterDrop] = useState([]);
    const handleDrop = (newTree) => {
        setTreeData(newTree);
    };

    // Setting parent id to children
    const setParent = () => {
        const data = [];
        labels.forEach((label) => {
            const nameArr = label.name.split('/');
            label.text = nameArr[nameArr.length - 1];
            const parentId = nameArr[nameArr.length - 2]
                ? labels.find(
                      (t) =>
                          t.name ===
                          nameArr
                              .filter((name, i) => i <= nameArr.length - 2)
                              .join('/')
                  ).id
                : 0;
            data.push({
                ...label,
                parent: parentId
            });
        });
        setTreeData(data);
    };

    // Setting name to element on drop method
    const setNameOnDrop = () => {
        const data = [];
        treeData.forEach((label) => {
            const parentId = label.parent;
            parentId
                ? data.push({
                      ...label,
                      name: treeData
                          .find((t) => t.id === parentId)
                          .name.concat('/', label.text)
                  })
                : data.push({
                      ...label,
                      name: label.text
                  });
        });
        console.log(data);
        setDataAfterDrop(data);
    };

    const fetchToGmailMethod = () => {
        dataAfterDrop.forEach((data) => {
            const dataObj = {
                id: `Label_${data.id}`,
                // labelListVisibility: 'labelShow',
                // messageListVisibility: 'hide',
                name: `${data.name}`
            };
            fetch(
                `https://gmail.googleapis.com/gmail/v1/users/${user}/labels/${
                    'Label_' + data.id
                }?access_token=${token}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataObj)
                }
            );
        });
    };

    useEffect(() => {
        setParent();
    }, [labels]);

    useEffect(() => {
        setNameOnDrop();
    }, [treeData]);

    useEffect(() => {
        fetchToGmailMethod();
    }, [dataAfterDrop]);

    return (
        <div className="labels_container">
            {labels !== undefined && labels.length > 0 ? (
                <Tree
                    tree={treeData}
                    rootId={0}
                    render={(node, { depth, isOpen, onToggle }) => (
                        <div style={{ marginInlineStart: depth * 50 }}>
                            <div className="label" onClick={onToggle}>
                                <span>{node.text}</span>{' '}
                                {node.droppable && (
                                    <span onClick={onToggle}>
                                        {isOpen ? '' : <span>&#8627;</span>}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    classes={{
                        dropTarget: 'dropTarget',
                        draggingSource: 'draggingSource'
                    }}
                    onDrop={handleDrop}
                    initialOpen={true}
                />
            ) : (
                'Your session expired. Please Log In again.'
            )}
        </div>
    );
};

export default Labels;
